<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar-menu></sidebar-menu>
    <div class="page-wrapper">
      <div class="content">
        <pageheader :title="title" :title1="title1" />

        <!-- /add -->
        <div class="card">
          <div class="card-body">
            <!-- <Form @submit="update" :validation-schema="schema" v-slot="{ errors }"> -->
            <form @submit.prevent="proses">
              <pageheader title="Employee Data" title1="" />

              <div class="row">
                <!-- <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>ID karyawan</label>
                    <input type="text" v-model="currentData.employee_id" placeholder="" />
                  </div>
                </div> -->
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Employee<span class="text-danger">*</span></label>
                    <vue-select :options="employeeList" :settings="{ settingOption: value, settingOption: value }"
                      v-model="currentData.employee_id" placeholder="None" required disabled>
                    </vue-select>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Date <span class="text-danger">*</span></label>
                    <div class="input-groupicon" style="z-index: 100;">
                      <datepicker v-model="currentData.tanggal" class="picker" :editable="true" :clearable="false"
                        required disabled />
                      <a class="addonset">
                        <img src="../../../assets/img/icons/calendars.svg" alt="img" />
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Type of Attendance <span class="text-danger">*</span></label>
                    <vue-select :options="Jeniskehadiranlist" :settings="{ settingOption: value, settingOption: value }"
                      v-model="currentData.jenis_kehadiran" placeholder="None" required>
                    </vue-select>
                  </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Clock in <span class="text-danger">*</span></label>
                    <input type="time" class="form-control" v-model="currentData.jam_masuk" required />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Clock out<span class="text-danger">*</span></label>
                    <input type="time" class="form-control" v-model="currentData.jam_pulang" required />
                  </div>
                </div>
                <!-- <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Entrance Location</label>
                    <vue-select :options="LocationList" :settings="{ settingOption: value, settingOption: value }"
                      v-model="currentData.lokasi_masuk" placeholder="None">
                    </vue-select>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Exit Location</label>
                    <vue-select :options="LocationList" :settings="{ settingOption: value, settingOption: value }"
                      v-model="currentData.lokasi_pulang" placeholder="None">
                    </vue-select>
                  </div>
                </div> -->
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Entrance Address </label>
                    <textarea class="form-control" v-model="currentData.alamat_masuk">
                    </textarea>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Exit Address </label>
                    <textarea class="form-control" v-model="currentData.alamat_pulang">
                    </textarea>
                  </div>
                </div>
                <div class="col-lg-12">
                  <button type="submit" class="btn btn-submit me-2">update</button>
                  <router-link to="employee" class="btn btn-cancel">Cancel</router-link>
                </div>
              </div>

            </form>
          </div>
        </div>
        <!-- /add -->
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import axios from "axios";
import { ShowLoading, CloseLoading, ErrorConnectionTimeOut, base_url, AlertPopup, goPage, formatCurrency, rupiah, rupiahoutput, rupiahInput, AlertBottom } from "@/assets/js/function";

// import { Form, Field } from "vee-validate";
// import * as Yup from "yup";

export default {
  // components: {
  //   Form,
  //   Field,
  // },
  name: "editemployee",
  data() {
    return {
      filter: true,
      title: "Employee Insert",
      title1: "add new employee",
      config: "",
      api: "",
      foto_ktp_img: "",

      // select option
      TipeKerjaList: ["MONTH", "DAILY"],
      ShiftList: [],
      LocationList: [],
      AttendanceList: ["SCANQR", "SELFIE", "FINGERPRINT"],
      GenderList: ["MALE", "FEMALE"],
      ReligionList: ["ISLAM", "KRISTEN PROTESTAN", "KRISTEN KATOLIK", "HINDU", "BUDDHA", "KONGHUCU"],
      DepartementList: [],
      JobTitleList: [],
      employeeList: [],
      GolonganList: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
      StatusPajakList: [],

      //data
      currentData: {
        employee_id: "",
        tanggal: new Date(),
        jenis_kehadiran: "",
        jam_masuk: "",
        jam_pulang: "",
        lokasi_masuk: 0,
        lokasi_pulang: 0,
        alamat_masuk: "",
        alamat_pulang: "",
      },
      currentDate: new Date,


      Computers: ["Computers", "Mac"],
      None: ["None", "option1"],
      Tax: ["Choose Tax", "2%"],
      Percentage: ["Percentage", "10%", "20%"],
      Piece: ["Piece", "Kg"],
      Active: ["Active", "Open"],
      Jeniskehadiranlist: [
        {
          "id": "HADIR",
          "text": "PRESENT"
        },
        {
          "id": "IZIN",
          "text": "LEAVE"
        },
        {
          "id": "CUTI",
          "text": "ANNUAL LEAVE"
        },
        {
          "id": "TERLAMBAT",
          "text": "LATE"
        },
        {
          "id": "ABSEN",
          "text": "ABSENT"
        },
        {
          "id": "LEMBUR",
          "text": "OVERTIME"
        }
      ],
    };
  },

  created() {
    const token = Cookies.get("token_refresh");
    this.config = {
      headers: {
        Authorization: `Bearer ` + token,
      },
      timeout: 30000,
    }

    //load employee data
    this.currentData = JSON.parse(localStorage.getItem('currentData'));
    this.currentData.tanggal = new Date(this.currentData.tanggal_asli);

    //load location
    const location = JSON.parse(localStorage.getItem('locationList'));
    location.forEach((value, index) => {
      const element = { id: value.id, text: value.nama_lokasi }
      this.LocationList.push(element);
    });

    //set employee
    const employee = JSON.parse(localStorage.getItem('employeeList'));
    employee.forEach((value, index) => {
      const element = { id: value.id, text: value.nama }
      this.employeeList.push(element);
    });

  },
  // setup() {

  //   const schema = Yup.object().shape({
  //     name: Yup.string().required("Phone Number is required"),

  //   });

  //   return {
  //     schema,

  //   };
  // },
  methods: {
    rupiah, rupiahoutput,
    scrollToInvalidElement() {
      const invalidElement = document.querySelector('.is-invalid');
      if (invalidElement && typeof invalidElement.scrollIntoView === 'function') {
        invalidElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    },
    formatCurrency(number, index) {
      this.currentData.salary[index].nilai = rupiahInput(number);
    },
    getNameFileUrl(url) {
      const parts = url.split('/');
      const fileName = parts[parts.length - 1];
      return fileName;
    },
    proses() {
      ShowLoading();
      let formData = new FormData();

      Object.entries(this.currentData).forEach(([key, value]) => {
        formData.append(key, value);
      });

      //set dob
      formData.delete("tanggal");
      formData.delete("tanggal_asli");
      if (this.currentData.tanggal != 'Invalid Date') {
        // var formattedDate = this.currentData.tanggal.toISOString().split('T')[0];
        var dateObj = new Date(this.currentData.tanggal);

        // Format the date manually (e.g., yyyy-mm-dd)
        var year = dateObj.getFullYear();
        var month = ('0' + (dateObj.getMonth() + 1)).slice(-2); // Add leading zero for single digits
        var day = ('0' + dateObj.getDate()).slice(-2); // Add leading zero for single digits

        var formattedDate = `${year}-${month}-${day}`;
        formData.append("tanggal", formattedDate);
      }

      // for (let [key, value] of formData.entries()) {
      //   console.log(`${key}: ${value}`);
      // }



      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'kehadiran/update';
      } else {
        this.api = base_url + 'kehadiran/update';
      }

      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status == true) {
            AlertBottom(response.data.message);
            goPage("historyattendance");
            CloseLoading();
          }
          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });




    },
  },

};
</script>
