<template>
    <!-- Main Wrapper -->
    <div class="main-wrapper">
        <layouts></layouts>
        <sidebar-menu></sidebar-menu>
        <div class="page-wrapper">
            <div class="content">
                <pageaddheader :title="title" :title1="title1" />
                <!-- /product list -->
                <div class="card">
                    <div class="card-body">
                        <div class="table-top">
                            <div class="search-set">
                                <!-- <div class="search-path">
                                    <a class="btn btn-filter" id="filter_search" v-on:click="filter = !filter"
                                        :class="{ setclose: filter }">
                                        <img src="../../../assets/img/icons/filter.svg" alt="img" />
                                        <span><img src="../../../assets/img/icons/closes.svg" alt="img" /></span>
                                    </a>
                                </div> -->
                                <div class="search-input">
                                    <a class="btn btn-searchset"><img src="../../../assets/img/icons/search-white.svg"
                                            alt="img" /></a>
                                    <div id="DataTables_Table_0_filter" class="dataTables_filter">
                                        <label>
                                            <input type="search" class="form-control form-control-sm"
                                                placeholder="Search..." aria-controls="DataTables_Table_0" />
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="wordset">
                                <ul>
                                    <li>
                                        <a data-bs-toggle="tooltip" data-bs-placement="top" title="pdf"><img
                                                src="../../../assets/img/icons/pdf.svg" alt="img" /></a>
                                    </li>
                                    <li>
                                        <a data-bs-toggle="tooltip" data-bs-placement="top" title="excel"><img
                                                src="../../../assets/img/icons/excel.svg" alt="img" /></a>
                                    </li>
                                    <li>
                                        <a data-bs-toggle="tooltip" data-bs-placement="top" title="print"><img
                                                src="../../../assets/img/icons/printer.svg" alt="img" /></a>
                                    </li>
                                </ul>
                            </div> -->
                        </div>
                        <!-- /Filter -->
                        <div class="card mb-0" id="filter_inputs" :style="{
                            display: filter ? 'block' : 'none',
                        }">
                            <div class="card-body pb-0">
                                <div class="row">
                                    <div class="col-lg-12 col-sm-12">
                                        <div class="row">
                                            <div class="col-lg col-sm-6 col-12">
                                                <div class="form-group">
                                                    <vue-select :options="Product" placeholder="Choose Product" />
                                                </div>
                                            </div>
                                            <div class="col-lg col-sm-6 col-12">
                                                <div class="form-group">
                                                    <vue-select :options="Choose" placeholder="Choose Category" />
                                                </div>
                                            </div>
                                            <div class="col-lg col-sm-6 col-12">
                                                <div class="form-group">
                                                    <vue-select :options="Category" placeholder="Choose Sub Category" />
                                                </div>
                                            </div>
                                            <div class="col-lg col-sm-6 col-12">
                                                <div class="form-group">
                                                    <vue-select :options="Brand" placeholder="Brand" />
                                                </div>
                                            </div>
                                            <div class="col-lg col-sm-6 col-12">
                                                <div class="form-group">
                                                    <vue-select :options="Price" placeholder="Price" />
                                                </div>
                                            </div>
                                            <div class="col-lg-1 col-sm-6 col-12">
                                                <div class="form-group">
                                                    <a class="btn btn-filters ms-auto"><img
                                                            src="../../../assets/img/icons/search-whites.svg"
                                                            alt="img" /></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- /Filter -->
                        <div class="table-responsive">
                            <a-table :columns="columns" :data-source="data" @change="onChange">
                                <template #bodyCell="{ column, record }">
                                    <template v-if="column.key === 'no'">
                                        <div class="productimgname">
                                            {{ record.no }}
                                        </div>
                                    </template>
                                    <template v-if="column.key === 'id'">
                                        <div class="productimgname">
                                            <a href="javascript:void(0);" class="product-img">
                                                <img :src="record.logo" alt="product" />
                                            </a>
                                            <a href="javascript:void(0);">{{ record.name }}</a>
                                        </div>
                                    </template>
                                    <template v-else-if="column.key === 'action'">
                                        <!-- <a class="me-3" @click="show_izitoast">
                                            <img src="../../../assets/img/icons/eye.svg" alt="img" />
                                        </a> -->
                                        <button type="button" class="me-3 btn " @click="edit(record);">
                                            <img src="../../../assets/img/icons/edit.svg" alt="img" />
                                        </button>
                                        <!-- <a class="confirm-text" href="javascript:void(0);"
                                            @click="terminate(record.id); showModal('terminate');">
                                            <img src="../../../assets/img/icons/delete.svg" alt="img" />
                                        </a> -->
                                    </template>
                                </template>
                            </a-table>
                        </div>
                    </div>
                </div>
                <!-- /product list -->
            </div>
        </div>
    </div>
    <!-- <button type="button" class="btn btn-primary" @click="">
        Launch demo modal
    </button> -->

    <div class="modal fade" id="terminate" ref="terminate" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLongTitle">Delete Data</h5>
                    <button type="button" class="close" @click="hideModal2('terminate')" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <form @submit.prevent="delete2">
                    <div class="modal-body">
                        Are u sure want to delete this data?
                    </div>
                    <div class="modal-footer d-flex justify-content-end">
                        <button type="button" class="btn  btn-secondary"
                            @click="hideModal2('terminate')">cancel</button>
                        <button type="submit" class="btn  btn-danger">Delete</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>

import Cookies from "js-cookie";
import axios from "axios";
import { ShowLoading, CloseLoading, ErrorConnectionTimeOut, base_url, AlertPopup, AlertBottom, goPage } from "@/assets/js/function";

export default {
    data() {
        return {
            filter: false,
            title: "Connected Apps List",
            title1: "Manage connected apps",
            items: [
                {
                    text: "Add New Location",
                    addname: "/addlocation",
                },
            ],
            Product: ["Choose Product", "Macbook pro", "Orange"],
            Category: ["Choose Sub Category", "Computer"],
            Choose: ["Choose Category", "Computers", "Fruits"],
            Brand: ["Brand", "N/D"],
            Price: ["Price", "150.00"],

            //data
            data: [


            ],
            currentData: [],
            columns: [
                {
                    title: "No",
                    dataIndex: "no",
                    key: "no",
                    sorter: {
                        compare: (a, b) => {
                            a = a.no.toLowerCase();
                            b = b.no.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },
                {
                    title: "Name",
                    dataIndex: "name",
                    key: "id",
                    sorter: {
                        compare: (a, b) => {
                            a = a.name.toLowerCase();
                            b = b.name.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },

                {
                    title: "Action",
                    key: "action",
                    sorter: false,
                },
            ],

            jumlah_lokasi: "",

            tanggal_terminate: "",
            alasan_terminate: "",
            id_terminate: "",
        };
    },
    created() {
        const token = Cookies.get("token_refresh");
        this.config = {
            headers: { Authorization: `Bearer ` + token },
            timeout: 30000,
        }
        this.index();
    },
    methods: {
        show_izitoast() {
            AlertBottom('tessst');
        },
        showModal(modal_name) {
            this.$refs[modal_name].classList.add('show');
            this.$refs[modal_name].style.display = 'block';
        },

        hideModal2(modal_name) {
            this.$refs[modal_name].classList.remove('show');
            this.$refs[modal_name].style.display = 'none';

        },
        cekStatus() {
            ShowLoading();

            if (process.env.NODE_ENV === "development") {
                this.api = this.currentData.callback_status;
            } else {
                this.api = this.currentData.callback_status;
            }

            axios
                .post(this.api, '', this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        goPage('editconnectedapps');
                        CloseLoading();
                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },


        // showAlert() {
        //     // Use sweetalert2
        //     Swal.fire({
        //         title: "Are you sure?",
        //         text: "You won't be able to revert this!",
        //         showCancelButton: true,
        //         confirmButtonColor: "#08b77e",
        //         cancelButtonColor: "#d33",
        //         confirmButtonText: "Yes, delete it!",
        //     }).then((result) => {
        //         if (result.isConfirmed) {
        //             Swal.fire("Deleted!", "Your file has been deleted.");
        //         }
        //     });
        // },

        index() {
            ShowLoading();

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'applications/index';
            } else {
                this.api = base_url + 'applications/index';
            }
            axios
                .post(this.api, '', this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        this.data = [];
                        response.data.data.application.forEach((item, index) => {
                            let item_prepare = { ...item, no: (index + 1), };
                            this.data.push(item_prepare);
                        });
                        CloseLoading();
                    }


                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },

        edit(record) {
            localStorage.setItem('currentData', JSON.stringify(record));
            this.currentData = record;
            if (this.currentData.callback_register == undefined) {
                this.cekStatus();
            } else {
                goPage('editconnectedapps');
            }
        },
        delete2() {
            ShowLoading();
            let formData = new FormData();
            formData.append("id", this.id_terminate);
            // const tanggal = this.tanggal_terminate.toISOString().split('T')[0];

            var dateObj = new Date(this.tanggal_terminate);

            // Format the date manually (e.g., yyyy-mm-dd)
            var year = dateObj.getFullYear();
            var month = ('0' + (dateObj.getMonth() + 1)).slice(-2); // Add leading zero for single digits
            var day = ('0' + dateObj.getDate()).slice(-2); // Add leading zero for single digits

            var tanggal = `${year}-${month}-${day}`;
            formData.append("tanggal", tanggal);
            formData.append("alasan", this.alasan_terminate);

            // for (let [key, value] of formData.entries()) {
            //   console.log(`${key}: ${value}`);
            // }

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'lokasi_delete';
            } else {
                this.api = base_url + 'lokasi_delete';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        const index = this.data.findIndex((record) => record.id === this.id_terminate);
                        this.data.splice(index, 1);
                        CloseLoading();
                        AlertBottom(response.data.message);
                        this.hideModal2('terminate');
                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {

                    ErrorConnectionTimeOut(error);
                });


        },
        terminate(id) {
            this.id_terminate = id;
            this.tanggal_terminate = new Date();
        }
    },
    name: "productlist",
};
</script>
<style>
.ant-tooltip {
    display: none !important;
}
</style>