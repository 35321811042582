<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar-menu></sidebar-menu>
    <div class="page-wrapper">
      <div class="content">
        <pageheader :title="title" :title1="title1" />
        <!-- /product list -->
        <div class="card">
          <div class="card-body">
            <!-- <div class="profile-set"> -->
            <!-- <div class="profile-head"></div> -->
            <!-- <div class="profile-top">
                <div class="profile-content">
                  <div class="profile-contentimg">
                    <img
                      src="../../assets/img/customer/customer5.jpg"
                      alt="img"
                      id="blah"
                    />
                    <div class="profileupload">
                      <input type="file" id="imgInp" />
                      <a href="javascript:void(0);"
                        ><img src="../../assets/img/icons/edit-set.svg" alt="img"
                      /></a>
                    </div>
                  </div>
                  <div class="profile-contentname">
                    <h2>William Castillo</h2>
                    <h4>Updates Your Photo and Personal Details.</h4>
                  </div>
                </div>
                <div class="ms-auto">
                  <a href="javascript:void(0);" class="btn btn-submit me-2">Save</a>
                  <a href="javascript:void(0);" class="btn btn-cancel">Cancel</a>
                </div>
              </div> -->
            <!-- </div> -->


            <h5 class="fw-bold">Company Data</h5>
            <div class="text-danger mb-3">to change this please contact us in <a @click="cekBantuan()"
                class="fw-bold text-danger">here</a></div>

            <form @submit.prevent="update">
              <div class="row">




                <div class="col-lg-6 col-sm-12">
                  <div class="form-group">
                    <label>Company Name</label>
                    <input type="text" v-model="currentData.nama_perusahaan" disabled />
                  </div>
                </div>
                <div class="col-lg-6 col-sm-12">
                  <div class="form-group">
                    <label>Name </label>
                    <input type="text" v-model="currentData.nama_owner" disabled />
                  </div>
                </div>
                <div class="col-lg-6 col-sm-12">
                  <div class="form-group">
                    <label>Phone Number</label>
                    <input type="text" v-model="currentData.telepon" disabled />
                  </div>
                </div>
                <div class="col-lg-6 col-sm-12">
                  <div class="form-group">
                    <label>Total Employee</label>
                    <input type="text" v-model="currentData.jumlah_karyawan" disabled />
                  </div>
                </div>
                <div class="col-lg-6 col-sm-12">
                  <div class="form-group">
                    <label>Type of Bussines</label>
                    <input type="text" v-model="currentData.jenis_usaha" disabled />
                  </div>
                </div>

                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Address</label>
                    <textarea class="form-control" v-model="currentData.alamat" disabled>
                    </textarea>
                  </div>
                </div>

                <pageheader :title="'Operational'" />
                <div class="col-lg-6 col-sm-12">
                  <div class="form-group">
                    <label>Late Tolerance</label>
                    <div class="position-relative">
                      <input type="text" v-model="currentData.toleransi_keterlambatan" />
                      <div class="position-absolute end-0 top-0 d-flex align-items-center h-100 me-2">Minutes</div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-sm-12">
                  <div class="form-group">
                    <label>Present Tolerance</label>
                    <div class="position-relative">
                      <input type="text" v-model="currentData.toleransi_kehadiran" />
                      <div class="position-absolute end-0 top-0 d-flex align-items-center h-100 me-2">Hour</div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-sm-12">
                  <div class="form-group">
                    <label>Clock in</label>
                    <input type="time" class="form-control" v-model="currentData.jam_buka" />
                  </div>
                </div>
                <div class="col-lg-6 col-sm-12">
                  <div class="form-group">
                    <label>Clock out</label>
                    <input type="time" class="form-control" v-model="currentData.jam_tutup" />
                  </div>
                </div>
                <div class="col-lg-6 col-sm-12">
                  <div class="form-group">
                    <label>Break out</label>
                    <input type="time" class="form-control" v-model="currentData.jam_break_out" />
                  </div>
                </div>
                <div class="col-lg-6 col-sm-12">
                  <div class="form-group">
                    <label>Break in</label>
                    <input type="time" class="form-control" v-model="currentData.jam_break_in" />
                  </div>
                </div>
                <!-- <div class="col-lg-6 col-sm-12">
                  <div class="form-group">
                    <label>Clock out</label>
                    <input type="time" class="form-control" v-model="currentData.jam_tutup" />
                  </div>
                </div> -->

                <div class="col-12">
                  <div class="form-group">
                    <label class="col-form-label col-md-2">Working Days</label>
                    <div class="d-flex flex-wrap justify-content-between gap-2">
                      <div class="checkbox">
                        <label>
                          <input type="checkbox" name="checkbox" v-model="currentData.senin" /> Monday
                        </label>
                      </div>
                      <div class="checkbox">
                        <label>
                          <input type="checkbox" name="checkbox" v-model="currentData.selasa" /> Tuesday
                        </label>
                      </div>
                      <div class="checkbox">
                        <label>
                          <input type="checkbox" name="checkbox" v-model="currentData.rabu" /> Wednesday
                        </label>
                      </div>
                      <div class="checkbox">
                        <label>
                          <input type="checkbox" name="checkbox" v-model="currentData.kamis" /> Thrusday
                        </label>
                      </div>
                      <div class="checkbox">
                        <label>
                          <input type="checkbox" name="checkbox" v-model="currentData.jumat" /> Friday
                        </label>
                      </div>
                      <div class="checkbox">
                        <label>
                          <input type="checkbox" name="checkbox" v-model="currentData.sabtu" /> Saturday
                        </label>
                      </div>
                      <div class="checkbox">
                        <label>
                          <input type="checkbox" name="checkbox" v-model="currentData.minggu" /> Sunday
                        </label>
                      </div>

                    </div>
                  </div>

                </div>
                <div class="col-12">
                  <button type="submit" class="btn btn-submit me-2">Submit</button>
                  <router-link type="button" class="btn btn-cancel" to="index">Cancel</router-link>
                </div>
              </div>
            </form>
          </div>
        </div>
        <!-- /product list -->
      </div>
    </div>
  </div>
</template>

<style>
input[type=checkbox] {
  accent-color: #08b77e;
}
</style>
<script>

import Cookies from "js-cookie";
import axios from "axios";
import { ShowLoading, CloseLoading, ErrorConnectionTimeOut, base_url, AlertPopup, AlertBottom } from "@/assets/js/function";

export default {
  name: "profile",
  data() {
    return {
      title: "Profile",
      title1: "User Profile",
      showPassword: false,
      password: null,

      config: "",
      api: "",
      currentData: [],
      workDay: ['senin', 'selasa', 'rabu', 'kamis', 'jumat', 'sabtu', 'minggu'],
    };
  },
  computed: {
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    index() {
      ShowLoading();
      let formData = new FormData();
      const currentDate = new Date();
      // const formattedDate = currentDate.toISOString().slice(0, 10);

      var dateObj = new Date(currentDate);

      // Format the date manually (e.g., yyyy-mm-dd)
      var year = dateObj.getFullYear();
      var month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
      var day = ('0' + dateObj.getDate()).slice(-2);
      var formattedDate = `${year}-${month}-${day}`;

      formData.append("periode", formattedDate);

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'setting_profile';
      } else {
        this.api = base_url + 'setting_profile';
      }
      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status == true) {

            this.currentData = response.data.data;

            for (let i = 0; i < this.workDay.length; i++) {
              const day = this.workDay[i];

              if (response.data.data[day] == '1') {
                this.currentData[day] = false
              } else {
                this.currentData[day] = true
              }
            }
            CloseLoading();
          }


          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {

          ErrorConnectionTimeOut(error);
        });

    },

    update() {
      ShowLoading();
      let formData = new FormData();

      Object.entries(this.currentData).forEach(([key, value]) => {
        formData.append(key, value);
      });

      for (let i = 0; i < this.workDay.length; i++) {
        const day = this.workDay[i];

        formData.delete(day);
        if (this.currentData[day] == false) {
          formData.append(day, '1');
        } else {
          formData.append(day, '0');
        }

      }

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'setting_profile_update';
      } else {
        this.api = base_url + 'setting_profile_update';
      }

      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status == true) {
            AlertBottom(response.data.message);
            CloseLoading();

          }
          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {

          ErrorConnectionTimeOut(error);
        });


    },
    cekBantuan() {
      ShowLoading();
      let formData = new FormData();
      const currentDate = new Date();
      // const formattedDate = currentDate.toISOString().slice(0, 10);

      var dateObj = new Date(currentDate);

      // Format the date manually (e.g., yyyy-mm-dd)
      var year = dateObj.getFullYear();
      var month = ('0' + (dateObj.getMonth() + 1)).slice(-2); // Add leading zero for single digits
      var day = ('0' + dateObj.getDate()).slice(-2); // Add leading zero for single digits

      var formattedDate = `${year}-${month}-${day}`;

      formData.append("periode", formattedDate);

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'dashboard_index';
      } else {
        this.api = base_url + 'dashboard_index';
      }
      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status == true) {
            window.open(response.data.data.telepon_bantuan, '_blank');
            CloseLoading();
          }


          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {

          ErrorConnectionTimeOut(error);
        });
    }


  },
  created() {
    const token = Cookies.get("token_refresh");
    this.config = {
      headers: { Authorization: `Bearer ` + token },
      timeout: 30000,
    }
    this.index();



  }

};
</script>
